<template>
  <div class="gereklibelge-all">
    <div class="gereklibelge">

      <h3>Hizmet sağlayıcının MERSİS numarası yoksa;</h3>

      <p>Tüm bilgi ve belgelerin süreci hızlandırabilmek adına <b>onkayit@iys.org.tr</b> adresine göndermenizi rica ederiz.</p>

      <!-- <p>İmzaya yetkili kişinin Mersis üzerinde kaydı bulunması gerektiğini hatırlatmak isteriz ( <a class="blue" href="https://mersis.gtb.gov.tr/">https://mersis.gtb.gov.tr/</a> linke tıklayarak kayıtlı yetkili kişileri görüntüleyebilirsiniz).</p> -->

      <h4>Gerekli Bilgiler</h4>
      <div class="desc">Lütfen pdf formatında gönderiniz.</div>
      <ul>
        <li>İmzaya Yetkili kişi TCKN (Yabancı firma yetkilisi için Pasaport No)</li>
        <li>İmzaya Yetkili kişi ad soyad</li>
        <li>Yetkili kişi e-posta</li>
        <li>Yetkili kişi cep telefonu</li>
        <li>Ticaret Unvanı </li>
        <li>Vergi Kimlik Numarası</li>
        <li>Vergi Dairesi </li>
        <li>Web Sitesi</li>
        <li>İletişim Numarası</li>
        <li>İl</li>
        <li>İlçe</li>
        <li>İletişim Adresi</li>
      </ul>

      <h4>Gerekli Belgeler</h4>
      <div class="desc">Lütfen PDF formatında gönderiniz.</div>
      <ul>
        <li>Vergi levhası fotokopisi (yoksa vergi numarasını içeren belge)</li>
        <li>İşletme sahibinin nüfus cüzdanı fotokopisi</li>
        <li>İşletme yetkilisinin şirket unvanı altında düzenlenmiş imza sirküleri</li>
        <li>Varsa oda (meslek kuruluşu) kayıt belgesi</li>
        <li>Marka kullanımına yetkisi olduğunu gösteren belge (Marka tescil belgesi, yetkilendirme yazısı vb.)</li>
        <li>Kaşeli ve imzalı taahhütname <br>
          <small>(İYS Kullanım Taahhütnamesini tüm sayfaları kaşeli ve imzalı olacak şekilde tarafımıza kargo ile göndermenizi rica ediyoruz. Diğer belgelerin gönderilmesi gerekli değildir.).</small><br>
          <a
            class="blue downloadpdf mt1"
            target="_blank"
            href="/pdf/İleti_Yönetim_Sistemi_Temel_Hizmetler_Kullanım_Taahhütnamesi_fizikselimza.pdf"
          >Taahhütnameyi indirmek için tıklayınız</a>
        </li>

      </ul>

      <p><b>Kargo Adresimiz;</b>
        İleti Yönetim Sistemi A.Ş. Hukuk Departmanı Adına TOBB İkiz Kuleleri C Blok 27.Kat Dumlupınar Bulv. No:252, 06530 Çankaya / Ankara</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "TaahhutnameNoMersis"
};
</script>

<style scoped>

.desc{background:#eff2f7;padding:6px 16px;border-radius:5px;color:#7d7e86; font-size: 0.95rem; margin: -10px 0 5px -5px;}

.downloadpdf {
  background: #fdfbfa;
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
  display: block;
  width: fit-content;
}
</style>
